import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    exportProjects(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/reports/projects/export', {
          params: queryParams,
          responseType: 'blob',
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    fetchProject(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/project/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProjects(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/project/detailList", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addProject(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/project", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateProject(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/project", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    modifyProject(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put("/project", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateApplication(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put("/project/application", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteProject(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/project", { params: data })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchStatistic(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/project_stage/getDetailList", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
