<template>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>過濾器</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>客戶</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="clientFilter"
              :filter="clientSearch"
              :options="clientOptions"
              label="client_code"
              class="w-100"
              :reduce="(val) => val.id"
              @input="(val) => $emit('update:clientFilter', val)"
            >
            <template #option="{client_code, company_name_cn}">
              [{{ client_code }}] {{ company_name_cn }}
            </template>
             <template #selected-option="{client_code, company_name_cn}">
              [{{ client_code }}] {{ company_name_cn }}
            </template>
            </v-select>
          </b-col>
          
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>項目狀態</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="stageFilter"
              :options="projectstageOptions"
              label="stage"
              class="w-100"
              :reduce="(val) => val.id"
              @input="(val) => $emit('update:stageFilter', val)"
            />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label></label>
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="reset"
                style="height: 40px"
              >
                重置
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormDatepicker,
    BButton,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import Ripple from "vue-ripple-directive";
  
  export default {
    directives: {
      Ripple,
    },
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardBody,
      vSelect,
      BFormDatepicker,
      BButton,
    },
    watch:{
      $route(to, from){
        console.log('route', to, from)
        this.checkRoute(to.name)
      }
    },  
    mounted(){
      let router_name = this.$router.currentRoute.name 
      this.checkRoute(router_name)
    },  
    methods: {
      checkRoute(router_name){
        if(router_name == 'projects-list-food-licence'){
          this.$emit("update:serviceFilter",1)
        }else if(router_name == 'projects-list-iso')
        {
          this.$emit("update:serviceFilter", 6)
        }else if(router_name == 'projects-list-internal-audit'){
          this.$emit("update:serviceFilter", 4)
        }else if(router_name == 'projects-list-crs'){
          this.$emit("update:serviceFilter", 2)
        }
      },
      reset() {
        this.$emit("update:userFilter", null);
        this.$emit("update:clientFilter", null);
        this.$emit("update:stageFilter", null);
      },
      clientSearch(options, search){
        var returnOptions = options.filter(item => {
          return item.client_code.toLowerCase().includes(search.toLowerCase()) || item.company_name.toLowerCase().includes(search.toLowerCase())
        })
          return returnOptions
      }
    },
    props: {
      clientFilter: {
        type: [Number, null],
        default: null,
      },
      userFilter: {
        type: [Number, null],
        default: null,
      },
      stageFilter: {
        type: [Number, null],
        default: null,
      },
      clientOptions: {
        type: Array,
        required: true,
      },
      projectstageOptions: {
        type: Array,
        required: true,
      },
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  