<template>
  <div>
    <!--  <project-list-statistic
      :data="statisticOptions"
      :stage-filter.sync="stageFilter"
    /> -->
    <project-list-filter
      :user-filter.sync="userFilter"
      :client-filter.sync="clientFilter"
      :client-options="clientOptions"
      :stage-filter.sync="stageFilter"
      :projectstage-options="projectstageOptions"
    />
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" class="mb-2" style="text-align: center">
            <h3 class="mb-0">項目資料報表</h3>
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            v-if="false"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" @click="exportReport">
                <span class="text-nowrap">導出Excel</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refProjectListTable"
        class="position-relative"
        :items="fetchProjects"
        responsive
        :fields="tableReportColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Client Code -->
        <template #cell(client_id)="data">
          <b-link
            :to="{
              name: 'clients-detail',
              params: { id: data.item.client_id },
            }"
            >{{ data.item.client.client_code }}</b-link
          >
        </template>
        <template #cell(file)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            @click="handleUpload(data.item)"
          >
            <feather-icon icon="UploadCloudIcon" />
          </b-button>
        </template>
        <template #cell(contract_id)="data">
          <span v-if="data.item.contract">{{
            data.item.contract.contract_id
          }}</span>
        </template>
        <!-- Column: APPLICATION CODE -->
        <template #cell(application_codes)="data">
          <b-badge
            class="text-capitalize"
            v-for="item in data.item.application_codes"
            :key="item.id"
            @click="openApplicationModal(data.item)"
          >
            {{ item.code_name }}
          </b-badge>
        </template>
        <template #cell(application_remark)="data">
          <div v-html="data.item.application_remark"></div>
        </template>

        <!-- Column: PROJECT STAGE -->
        <template #cell(project_stage)="data">
          <b-badge
            class="text-capitalize"
            v-if="data.item.project_stage"
            @click="openStageModal(data.item)"
            :style="{
              background: data.item.project_stage.background_color,
              color: data.item.project_stage.font_color,
            }"
          >
            {{ data.item.project_stage.stage }}
          </b-badge>
        </template>
      </b-table>
      <div class="mx-2 mb-2" v-if="false">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProjects"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import projectStoreModule from "@/views/project/projectStoreModule";
import useProjectsList from "@/views/project/projects-list/useProjectsList";
import Ripple from "vue-ripple-directive";
import ProjectListFilter from "@/views/project/projects-list/ProjectListFilter.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ProjectListFilter,
  },
  methods: {
    exportReport() {
      this.$swal({
        title: "確定導出Excel?",
        showCancelButton: true,
        confirmButtonText: "下載",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.$store
            .dispatch("project/exportProjects", {
              perPage: this.perPage,
              page: this.currentPage,
              sortBy: this.sortBy,
              sortDesc: this.isSortDirDesc,
              create_id: this.userFilter,
              client_id: this.clientFilter,
              project_stage_id: this.stageFilter,
              service_code_id: this.serviceFilter,
            })
            .then((response) => {
              // create file link in browser's memory
              const href = URL.createObjectURL(response.data);
              // create "a" HTLM element with href to file & click
              const link = document.createElement("a");
              link.href = href;
              //get current datetime
              const current_datetime = new Date();
              const formatted_date =
                current_datetime.getFullYear() +
                "-" +
                (current_datetime.getMonth() + 1) +
                "-" +
                current_datetime.getDate() +
                " " +
                current_datetime.getHours() +
                "-" +
                current_datetime.getMinutes() +
                "-" +
                current_datetime.getSeconds();
              link.setAttribute(
                "download",
                "Projects" + formatted_date + ".xlsx"
              ); // or any other extension
              document.body.appendChild(link);
              link.click();

              // clean up "a" element & remove ObjectURL
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  data() {
    return {
      id: 0,
      projectData: {},
      client_code: "",
      project_id: "",
      openModal: false,
      loading: false,
    };
  },
  setup() {
    const PROJECT_STORE_MODULE_NAME = "project";
    const statisticOptions = ref([]);

    if (!store.hasModule(PROJECT_STORE_MODULE_NAME))
      store.registerModule(PROJECT_STORE_MODULE_NAME, projectStoreModule);

    onUnmounted(() => {
      if (store.hasModule(PROJECT_STORE_MODULE_NAME))
        store.unregisterModule(PROJECT_STORE_MODULE_NAME);
    });

    const isAddNewProjectSidebarActive = ref(false);

    const applicationOptions = ref([]);
    const projectstageOptions = ref([]);
    const clientOptions = ref([]);
    const contractOptions = ref([]);
    store
      .dispatch("app/fetchOptionList", {
        client: true,
        project_stage: true,
        application_code: true,
        contract: true,
      })
      .then((response) => {
        clientOptions.value = response.data.clients;
        projectstageOptions.value = response.data.project_stages;
        applicationOptions.value = response.data.application_codes;
        contractOptions.value = response.data.contracts;
      })
      .catch((error) => {
        console.log(error);
      });

    const {
      fetchProjects,
      tableColumns,
      perPage,
      currentPage,
      totalProjects,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProjectListTable,
      refetchData,
      ability,
      userFilter,
      clientFilter,
      stageFilter,
      tableReportColumns,
    } = useProjectsList();

    return {
      //Sidebar
      isAddNewProjectSidebarActive,
      fetchProjects,
      tableColumns,
      perPage,
      currentPage,
      totalProjects,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProjectListTable,
      refetchData,
      avatarText,
      ability,
      userFilter,
      clientFilter,
      stageFilter,
      statisticOptions,
      projectstageOptions,
      clientOptions,
      applicationOptions,
      contractOptions,
      tableReportColumns,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.badge {
  width: 100%;
}
</style>
